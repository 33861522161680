"use client";

import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { useSession } from "@/lib/auth-client";
import React from "react";

const UserAvatar: React.FC = () => {
  const { data: session } = useSession();

  return (
    <Avatar>
      <AvatarImage
        src={session?.user?.image || ""}
        alt={session?.user?.name || ""}
      />
      <AvatarFallback>{session?.user?.name?.charAt(0) || ""}</AvatarFallback>
    </Avatar>
  );
};

export default UserAvatar;
